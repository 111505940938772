<template>
  <div class="add-new-customer">
    <title-bar
      submitBtn
      submitBtnTitle="Update customer"
      title="Update customer"
      @submitPressed="submitPressed"
    />
    <customer-form v-if="user" :userObject="user" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import CustomerForm from './components/CustomerForm.vue'
import router from '@/router'

export default {
  components: {
    TitleBar,
    CustomerForm
  },
  data() {
    return {
      user: null,
      action: null,
    }
  },
  created() {
    this.loadCustomer()
  },
  methods: {
    loadCustomer() {
      const { id } = router.currentRoute.params
      this.$Users.getResource({ id }).then(response => {
        this.user = response.data
      })
    },
    submitPressed() {
      this.action = 'update'
    },
  }
}
</script>
